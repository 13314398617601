<template>
<div class="rc-container">
  <div class="rc-row">
    <div class="rc-box-col-2 rc-text-centre rc-text-small">
      Picture
    </div>
    <div class="rc-box-col-13">
      <div class="rc-container">
        <div class="rc-row">
        
          <div class="rc-box-col-2 rc-text-small">
          {{ catelog.get(catelogKeys.VEHICLE.NAME_COLUMN_TITLE) }}
          </div>
          
          <div class="rc-box-col-1 rc-text-small">
          {{ catelog.get(catelogKeys.VEHICLE.STATUS_COLUMN_TITLE) }}
          </div>
          
          <div class="rc-box-col-2 rc-text-small">
          {{ catelog.get(catelogKeys.VEHICLE.BRAKETYPE_COLUMN_TITLE) }} / RGW
          </div>
          
          <div class="rc-box-col-1 rc-text-small">
          {{ catelog.get(catelogKeys.VEHICLE.ODOMETER_COLUMN_TITLE) }}
          </div>
          
          <div class="rc-box-col-2 rc-text-small">
          {{ catelog.get(catelogKeys.VEHICLE.PLATE_COLUMN_TITLE) }}
          </div>
          
          <div class="rc-box-col-2 rc-text-small">
           Last Safety / PMVI
          </div>
          
          <div class="rc-box-col-5 rc-text-small">
            Make / Model / VIN 
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Catelog from '@/domain/session/CanadianEnglish.js';

export default {
  name: 'vehicle-details-header',
  data() {
    return {
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,
    };
  },
}
</script>